import React, { useEffect, useMemo } from 'react';
import { Phases } from '@magicyard/magicsnakes-game/src/Game';
import { useGameObject } from '../store/GameContext';

import { Title } from './Title';
import { Display } from '@magicyard/shared/platform/lib/api';
import styles from './GameManager.module.css';
import { AudioManager } from '../AudioManager';
import { Playing } from './Stages/Playing';
import { Tutorial } from './Stages/Tutorial';
import { Background } from '../components/Background/Background';
import { DriftDbSocketProvider } from '@magicyard/shared/src/DriftDbSocketContext';

interface PhaseData {
  mainPage: () => JSX.Element;
  transition: (() => JSX.Element) | null;
  hasQr: boolean;
}

const Sync = () => {
  const { moves } = useGameObject();
  useEffect(() => {
    moves.endSync();
  }, []);
  return (
    <Title
      title={'Starting...'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        color: 'black',
      }}
    />
  );
};

const StageManager = ({ display }: { display: Display | undefined }) => {
  const { ctx, G } = useGameObject();
  const { isTransition } = G;
  const phase = ctx.phase as Phases;
  const phaseToScreen: Record<Phases, PhaseData> = useMemo(
    () => ({
      [Phases.Sync]: {
        mainPage: Sync,
        transition: null,
        hasQr: false,
      },
      [Phases.Tutorial]: {
        mainPage: Tutorial,
        transition: null,
        hasQr: false,
      },
      [Phases.Playing]: {
        mainPage: Playing,
        transition: null,
        hasQr: false,
      },
      [Phases.GameEnd]: {
        mainPage: Playing,
        transition: null,
        hasQr: false,
      },
    }),
    []
  );

  const curr = phaseToScreen[phase ?? Phases.GameEnd];
  return (
    <Background>
      <DriftDbSocketProvider socketUrl={G.playingSocketUrl}>
        <>
          <AudioManager phase={phase} isTransition={isTransition && curr.transition !== null} />
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundSize: 'contain',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {curr.hasQr && display !== undefined ? (
              <div className={styles['game_manager-qr']}>
                <div className={styles['game_manager-qr_body']}>room code: {display.code}</div>
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              {isTransition && curr.transition !== null ? (
                <curr.transition />
              ) : (
                <curr.mainPage key={ctx.phase === Phases.Playing || ctx.phase === Phases.GameEnd ? 1 : ctx.phase} />
              )}
            </div>
          </div>
        </>
      </DriftDbSocketProvider>
    </Background>
  );
};

export default StageManager;
