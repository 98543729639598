import { INVALID_MOVE } from 'boardgame.io/core';
import { CtxWithApi, GameState } from '../Types';
import { assertNever } from './typeUtils';
import { PlayerID } from 'boardgame.io';
import { getInitialMagicSnakesState, Phases } from '../Game';

const endSync = (G: GameState, ctx: CtxWithApi) => {
  console.log('ENDING SYNC PHASE');
  console.log(ctx.playerID);
  // Temp, the screen has the same playerId as the first player
  if (ctx.playerID === '0') {
    ctx.events.endPhase();
    return undefined;
  }
  return INVALID_MOVE;
};

// Simultaneous guessing.
const forceEndPhase = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Phase ${ctx.phase} was forced to end`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  const phase = ctx.phase as Phases;
  switch (phase) {
    case Phases.Sync:
      ctx.events.endPhase();
      break;
    case Phases.Tutorial:
    case Phases.Playing:
    case Phases.GameEnd:
      // Last phase, do nothing
      break;
    default:
      assertNever(phase);
  }
  return undefined;
};

const isMoveMadeByMasterClient = (ctx: CtxWithApi) => isIdMasterClient(ctx.playerID, ctx.numPlayers);
const isIdMasterClient = (id: string, numPlayers: number) => +id === numPlayers - 1;

const timesUp = {
  move: (G: GameState, ctx: CtxWithApi) => {
    console.log(`Player ${ctx.playerID} called timesUp on ${ctx.phase}`);

    if (!isMoveMadeByMasterClient(ctx)) {
      return INVALID_MOVE;
    }
    const phase = ctx.phase as Phases;
    switch (phase) {
      case Phases.Sync:
        ctx.events.endPhase();
        break;
      case Phases.Tutorial:
      case Phases.Playing:
      case Phases.GameEnd:
        break;
      default:
        assertNever(phase);
    }

    return undefined;
  },
  ignoreStaleStateID: true,
};

const transitionTimeUp = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Player ${ctx.playerID} ended the transiton for ${ctx.phase}`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  G.isTransition = false;
  return undefined;
};
const endTutorial = (G: GameState, ctx: CtxWithApi, completeTo: number) => {
  const players = [...G.players];
  while (players.length < completeTo) {
    players.push({
      avatarUrl: ['', '', ''][players.length - 1],
      controller_id: String(players.length),
      id: String(players.length),
      isBot: true,
      name: ['CurveBot', 'PythonBot', 'CobraBot', 'SlitherBot'][players.length - 1],
    });
  }

  ctx.events.endPhase();

  return { ...G, ...getInitialMagicSnakesState(players, G.playingSocketUrl), players: players };
};

const collectPowerUp = (G: GameState, ctx: CtxWithApi, pid: PlayerID) => {
  G.stateForPlayer[pid].score += 1;
};

const killPlayer = {
  move: (G: GameState, ctx: CtxWithApi, pid: string) => {
    G.stateForPlayer[pid].isDead = true;
    Object.keys(G.stateForPlayer).forEach((key) => {
      if (!G.stateForPlayer[key].isDead) {
        G.stateForPlayer[key].score += 1;
      }
    });
  },
  ignoreStaleStateID: true,
};

const endRound = {
  move: (G: GameState, ctx: CtxWithApi, pid: string) => {
    Object.keys(G.stateForPlayer).forEach((key) => {
      G.stateForPlayer[key].isDead = false;
    });
  },
};

export const movesUtil = {
  endSync,
  timesUp,
  forceEndPhase,
  transitionTimeUp,
  endTutorial,
  killPlayer,
  endRound,
  collectPowerUp,
};
