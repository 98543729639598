import React, { useState } from 'react';
import { Display, Queue, YardWithQueue } from '@magicyard/shared/platform/lib/api';
import styles from './QueueScreen.module.css';
import { QrSign } from './QrSign';
import useInterval from './hooks/useInterval';
import { YardPart } from './YardAndDisplay';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

interface QueueScreenProps {
  yard: YardWithQueue;
  display: Display;
}

export const QueueScreen = ({ yard, display }: QueueScreenProps) => {
  const [dots, setDots] = useState('.');
  const queue = yard.queue;

  useInterval(() => {
    setDots((d) => (d.length === 3 ? '.' : d + '.'));
  }, 1000);

  const allPlayers = queue.yards.reduce<BaseController[]>((acc, yard) => acc.concat(yard.controllers), []);
  const waitingFor = 5 - allPlayers.length;

  const dummies = Array(waitingFor)
    .fill(0)
    .map<BaseController>((_, idx) => ({
      profile: {
        id: '' + idx,
        name: dots,
        avatarUrl: '',
      },
      dummyData: { isOptional: waitingFor - idx < 3 },
      holdingMicState: null,
      isOnline: true,
    }));
  const allPlayersWithDummies = allPlayers.concat(dummies);

  return (
    <div className={styles['queue-screen_root']}>
      <div className={styles['queue-screen_white-bg']} />
      <div className={styles['queue-screen_red-carpet']} />
      <div className={styles['queue-screen_red-rope']} />
      <div className={styles['queue-screen_red-rope-shade']} />
      <div className={styles['queue-screen_arrow']} />
      <QrSign display={display} className={styles['queue-screen_qr']} size={300} />
      <div className={styles['queue-screen_text-container']}>
        <div className={styles['queue-screen_queueing-title']}>Queuing up{dots}</div>
        <div className={styles['queue-screen_queueing-subtitle']}>
          finding {waitingFor} more {waitingFor === 1 ? 'player' : 'players'}
        </div>
      </div>
      <div className={styles['queue-screen_faces-container']}>
        {/*<YardPart controllers={allPlayersWithDummies} voiceChatEnabled={false} key={1} startAnimating={true} test={}/>*/}
      </div>
    </div>
  );
};
