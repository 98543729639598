import React, { useMemo } from 'react';
import styles from './Background.module.css';

export interface BackgroundProps {
  children: React.ReactNode;
  flexDirection?: 'row' | 'column';
}

/**
 * @param children
 * @param onReached
 * @param camPosition Where to place the camera
 * @param renderPosition From to render the page, defaults to camPosition. If only this changed (and not camPosition) the old child will be maintained.
 * @constructor
 */

const spacing = 40;
const verticalLines = Math.ceil(1920 / spacing);
const horizontalLines = Math.floor(1080 / spacing);

export const Background = ({ children }: BackgroundProps) => {
  const lines = useMemo(() => {
    const lines: React.JSX.Element[] = [];
    for (let i = 0; i < verticalLines; i++) {
      lines.push(<div className={styles.background_vertical} style={{ left: i * spacing }} key={i} />);
    }
    for (let i = 0; i < horizontalLines; i++) {
      lines.push(<div className={styles.background_horizontal} style={{ top: i * spacing }} key={verticalLines + i} />);
    }

    return lines;
  }, []);

  return (
    <div className={styles['background_root']}>
      <span>{lines}</span>
      <div className={styles['background_child-container']}>{children}</div>
    </div>
  );
};
