import React from 'react';
import styles from './InitialLoading.module.css';

export const InitialLoading = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className={styles['initial-loading_root']} onClick={onClick}>
      {onClick !== undefined ? (
        <>
          <div className={styles['initial-loading_btn']}>Click to start!</div>
        </>
      ) : (
        'Loading...'
      )}
    </div>
  );
};
