import { Point } from '@magicyard/magicsnakes-game/src/Types';
import {
  calculateCollisionPoint,
  calculateDistance,
  closestCircleIntersectionWithPlayer,
  closestIntersectionWithPlayer,
  getRandomNumber,
  intersectionPointWithWall,
} from './Math';
import { CanvasSize, PlayerState } from './Playing';

type Probe = {
  lastAngleModifier: number;
  intersectionPoint: Point | null;
  baseAngle: number;
  strengthModifier: number;
  radiusModifier: number;
};

const probesForBot: Record<
  string,
  {
    probeCenter: Probe;
    probeLeft: Probe;
    probeRight: Probe;
    probeCenterLeft: Probe;
    probeCenterRight: Probe;
  }
> = {};

const circleProbesForBot: Record<string, { probeCenter: Probe }> = {};

export const initBots = (playerState: Record<string, PlayerState>) => {
  Object.keys(playerState)
    .filter((key) => playerState[key].isBot)
    .forEach((key) => initProbesForBot(key));
};

export const initProbesForBot = (key: string) => {
  probesForBot[key] = {
    probeCenter: {
      lastAngleModifier: 0,
      intersectionPoint: null,
      baseAngle: 0,
      strengthModifier: 1,
      radiusModifier: 1.2,
    },
    probeLeft: {
      lastAngleModifier: 0,
      intersectionPoint: null,
      baseAngle: 0.45,
      strengthModifier: 1,
      radiusModifier: getRandomNumber(0.5, 1),
    },
    probeRight: {
      lastAngleModifier: 0,
      intersectionPoint: null,
      baseAngle: -0.45,
      strengthModifier: 1,
      radiusModifier: getRandomNumber(0.5, 1),
    },
    probeCenterLeft: {
      lastAngleModifier: 0,
      intersectionPoint: null,
      baseAngle: 0.25,
      strengthModifier: 1,
      radiusModifier: getRandomNumber(0.6, 1.4),
    },
    probeCenterRight: {
      lastAngleModifier: 0,
      intersectionPoint: null,
      baseAngle: -0.25,
      strengthModifier: 1,
      radiusModifier: getRandomNumber(0.6, 1.4),
    },
  };

  circleProbesForBot[key] = {
    probeCenter: {
      radiusModifier: 10,
      lastAngleModifier: 0,
      baseAngle: 0,
      intersectionPoint: null,
      strengthModifier: 0.5,
    },
  };
};
const baseProbeRadius = 80;

// Ways to improve this bot if needed:
// Make the probes work on a moving avg, this way they won't be effected by a gap in the line next to them as much
export function stepBot(
  defaultRadius: number,
  canvasSize: CanvasSize,
  allPlayerState: Record<string, PlayerState>,
  key: string,
  curr: PlayerState,
  // For debugging
  canvasCtx: CanvasRenderingContext2D,
  head: Point
): {
  left: boolean;
  right: boolean;
} {
  const directionSum = { left: 0, right: 0 };

  for (const circleProbeKey in circleProbesForBot[key]) {
    const probe = (circleProbesForBot[key] as Record<string, Probe>)[circleProbeKey];
    if (probe.intersectionPoint === null) {
      probe.lastAngleModifier = getRandomNumber(-0.1, 0.1);
    }
    const angle = (probe.lastAngleModifier + probe.baseAngle) * Math.PI;
    const circleCenter = calculateCollisionPoint(curr.rot, angle, (defaultRadius * probe.radiusModifier) / 3.5, head);
    probe.intersectionPoint = closestCircleIntersectionWithPlayer(
      circleCenter,
      key,
      allPlayerState,
      defaultRadius * probe.radiusModifier
    );
    if (probe.intersectionPoint !== null) {
      if (angle > 0) {
        directionSum.left += probe.strengthModifier / calculateDistance(head, circleCenter);
      } else {
        directionSum.right += probe.strengthModifier / calculateDistance(head, circleCenter);
      }
    }
    // Temp for debugging
    // canvasCtx.beginPath();
    // canvasCtx.arc(circleCenter.x, circleCenter.y, defaultRadius * probe.radiusModifier, 0, 2 * Math.PI);
    // canvasCtx.stroke();
    // canvasCtx.closePath();
  }

  for (const probeKey in probesForBot[key]) {
    const probe = (probesForBot[key] as Record<string, Probe>)[probeKey];
    if (probe.intersectionPoint === null) {
      probe.lastAngleModifier = getRandomNumber(-0.1, 0.1);
    }

    const angle = (probe.lastAngleModifier + probe.baseAngle) * Math.PI;

    const tip = calculateCollisionPoint(curr.rot, angle, probe.radiusModifier * baseProbeRadius, head);
    probe.intersectionPoint =
      closestIntersectionWithPlayer(head, key, allPlayerState, [tip]) ??
      intersectionPointWithWall(canvasSize, head, tip);
    if (probe.intersectionPoint !== null) {
      if (angle > 0) {
        directionSum.left += probe.strengthModifier / calculateDistance(head, probe.intersectionPoint);
      } else {
        directionSum.right += probe.strengthModifier / calculateDistance(head, probe.intersectionPoint);
      }
    }

    // Temp for debugging
    // const pointToDrawTo = probe.intersectionPoint ?? tip;
    // canvasCtx.beginPath();
    // canvasCtx.moveTo(head.x, head.y);
    // canvasCtx.lineWidth = probe.strengthModifier;
    // canvasCtx.lineTo(pointToDrawTo.x, pointToDrawTo.y);
    // canvasCtx.moveTo(-head.x, -head.y);
    // canvasCtx.stroke();
    // canvasCtx.arc(pointToDrawTo.x, pointToDrawTo.y, 5, 0, 2 * Math.PI);
    // canvasCtx.fill();
    // canvasCtx.closePath();
  }
  return { left: directionSum.left > directionSum.right, right: directionSum.right > directionSum.left };
}
