import { getControllerUrlConnectDisplay } from '@magicyard/utils';
import { Display, generateRandomControllers, Profile, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { URL_SHORTENING_API } from '@magicyard/shared/platform/lib/urlShortener';

import React, { useEffect, useRef } from 'react';
import styles from './YardAndDisplay.module.css';
import { track, updateAnalyticsData } from '@magicyard/shared/src/localAnalytics';
import { AskToUsePhoneOverlay } from './AskToUsePhoneOverlay';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { useSamsungBack } from './App';

interface YardAndDisplayProps {
  yard: YardWithGame;
  display: Display;
  test: 'A' | 'B';
}

export const YardAndDisplay = ({ yard, display, test }: YardAndDisplayProps) => {
  useEffect(() => {
    updateAnalyticsData({ displayId: display.id, yardId: yard.id });
  }, [display, yard]);

  useEffect(() => {
    if (yard.controllers.length !== 0) {
      updateAnalyticsData({ numOfPlayers: yard.controllers.length, voiceChatAvailable: yard.voiceChatState !== null });
      track('Yard With Players Loaded');
    }
  }, [yard.controllers.length]);

  useEffect(() => track('Yard And QR Loaded'), []);
  useSamsungBack('Are you sure you want to exit?', () => {
    window.history.back();
  });
  return (
    <>
      <AskToUsePhoneOverlay />
      <div className={styles['yard-and-display_root']}>
        <div className={styles.scanToPlay}>Scan to play</div>
        <div
          className={`${styles['yard-and-display_qr-child-container']}${' '} 
          ${styles[`yard-and-display_qr-child-container-${test}`]}`}
        >
          <div
            className={styles['yard-and-display_qr']}
            style={{
              backgroundImage: `url(${URL_SHORTENING_API}/qr?text=${encodeURIComponent(
                getControllerUrlConnectDisplay(display.id)
              )})`,
            }}
          />
          <div className={styles['yard-and-display_code']}>
            <span style={{ fontSize: 37, fontWeight: 200 }}>or, on your phone go to </span>
            <span style={{ fontSize: 42, textDecoration: 'underline' }}>magicyard.tv</span>
            <br />
            Room code: {display.code}
          </div>
        </div>
        <YardPart
          test={test}
          controllers={yard.controllers.concat(generateRandomControllers(0))}
          startAnimating={true}
          voiceChatEnabled={false}
        />
      </div>
    </>
  );
};

export const YardPart = ({
  controllers,
  test,
}: {
  controllers: Array<BaseController & { dummyData?: { isOptional: boolean } }>;
  voiceChatEnabled: boolean | null;
  startAnimating: boolean;
  test: 'A' | 'B';
}) => {
  return (
    <div>
      {controllers.map((c, index) => {
        return (
          <div
            key={c.profile.id}
            style={{ position: 'absolute', ...indexToPosition(index, test), textAlign: 'center' }}
          >
            <PlayerSelfie profile={c.profile} dummyData={c.dummyData} />
          </div>
        );
      })}
    </div>
  );
};

export const PlayerSelfie = ({ profile, dummyData }: { profile: Profile; dummyData?: { isOptional: boolean } }) => {
  const delay = useRef(Math.random() * 3);
  return (
    <div
      className={styles['pick_team-player_frame']}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        className={`${styles['selfie']} ${dummyData?.isOptional === true ? styles['selfie_optional'] : ''}`}
        style={{ backgroundImage: `url(${profile.avatarUrl})`, animationDelay: delay + 's' }}
      />
      <div
        className={`${styles['selfie_name']} ${' '} 
        ${dummyData !== undefined ? styles['selfie_name-dummy'] + ' ' : ''}
        ${dummyData?.isOptional === true ? styles['selfie_name-optional'] + ' ' : ''}`}
      >
        {profile.name}
        <span className={dummyData !== undefined ? styles['selfie_name-dummy-spacing'] : ''}>
          {dummyData?.isOptional === true ? '\nOptional' : dummyData?.isOptional === false ? '\nPlayer' : ''}
        </span>
      </div>
    </div>
  );
};

const indexToPosition = (index: number, test: 'A' | 'B') => {
  const top = (index % 4) * 200 + 150;
  const left = index < 4 ? 200 : 400;
  return {
    left,
    top,
  };
};
