import type { BoardProps } from 'boardgame.io/react';
import { IPlayer, GameState, BoardPropTypes, CtxWithApi, MagicSnakesGameState, PlayerState } from './Types';
import { Game, PhaseConfig, PlayerID } from 'boardgame.io';
import { movesUtil } from './utils/moves.util';
import { assertIsDefined } from './utils/typeUtils';
import { ActivePlayers } from 'boardgame.io/core';

export enum Phases {
  Sync = 'Sync',
  Tutorial = 'Tutorial',
  Playing = 'Playing',
  GameEnd = 'gameEnd',
}

interface SetupData {
  playingSocketUrl: string;
  players?: IPlayer[];
  extras?: any;
}

export type G = GameState;

export const getInitialMagicSnakesState = (players: IPlayer[], socketUrl: string): MagicSnakesGameState => {
  return {
    stateForPlayer: players.reduce<Record<PlayerID, PlayerState>>((acc, player) => {
      acc[player.id] = { score: 0, isDead: false };
      return acc;
    }, {}),
    playingSocketUrl: socketUrl,
  };
};

type BoardPropsExtended = {
  moves: { [key in keyof typeof movesUtil]: (...arg: any) => void };
  ctx: CtxWithApi;
  playerID: PlayerID;
} & BoardProps<BoardPropTypes>;
export type GameObject = BoardPropsExtended;

let isPlayAgain = false;
const setup = (ctx: CtxWithApi, setupData?: SetupData): GameState => {
  // Weird server bug that only happens first time on local
  if (setupData === undefined) {
    return {} as any;
  }
  isPlayAgain = setupData?.extras?.isPlayAgain ?? false;

  assertIsDefined(setupData.players);
  const initPlayers = setupData.players;
  const players: IPlayer[] = initPlayers.map((player, index) => {
    return {
      id: index.toString(),
      name: player?.name || `Player ${index}`,
      avatarUrl: player?.avatarUrl || `https://i.pravatar.cc/300?img=${Math.random()}`,
      active: true,
      controller_id: player?.controller_id,
      isBot: false,
    };
  });

  return {
    didGameEnd: false,
    isTransition: true,
    phaseStartTime: Date.now(),
    players,
    shouldForceSubmit: false,
    ...getInitialMagicSnakesState(players, setupData.playingSocketUrl),
  };
};

const basePhase = {
  turn: {
    activePlayers: ActivePlayers.ALL,
  },
  moves: {
    timesUp: movesUtil.timesUp,
    forceEndPhase: movesUtil.forceEndPhase,
    transitionTimeUp: movesUtil.transitionTimeUp,
  },
  onEnd: (G: G) => {
    G.isTransition = true;
    G.shouldForceSubmit = false;
    G.phaseStartTime = Date.now();
  },
};

const AllPhases: Record<Phases, PhaseConfig<G, CtxWithApi>> = {
  [Phases.Sync]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      endSync: movesUtil.endSync,
    },
    start: true,
    next: Phases.Tutorial,
  },
  [Phases.Tutorial]: {
    ...basePhase,
    next: Phases.Playing,
    moves: {
      ...basePhase.moves,
      endTutorial: movesUtil.endTutorial,
    },
  },
  [Phases.Playing]: {
    ...basePhase,
    next: Phases.GameEnd,
    moves: {
      ...basePhase.moves,
      killPlayer: movesUtil.killPlayer,
      endRound: movesUtil.endRound,
      collectPowerUp: movesUtil.collectPowerUp,
    },
  },
  [Phases.GameEnd]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
    },
  },
};

export const getWinner = (G: GameState) =>
  Object.keys(G.stateForPlayer).reduce(
    (acc, playerId) => {
      if (G.stateForPlayer[playerId].score > acc.score) {
        return { playerId, score: G.stateForPlayer[playerId].score };
      }
      return acc;
    },
    { playerId: '0', score: 0 }
  );

export const GAME_ID = 'magicsnakes';

export const MagicSnakes: Game<G, CtxWithApi> = {
  name: GAME_ID,
  setup: setup,
  moves: movesUtil,
  phases: AllPhases,
};
