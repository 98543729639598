import styles from './AskToUsePhoneOverlay.module.css';
import React from 'react';
import { Title } from './GameManager/Title';

export const AskToUsePhoneOverlay = () => {
  const [isAnimating, setIsAnimating] = React.useState(false);

  return (
    <div
      className={`${styles['use-phone-overlay_root']} ${isAnimating ? styles['use-phone-overlay_root-animated'] : ''}`}
      onAnimationEnd={() => setIsAnimating(false)}
      onClick={() => setIsAnimating(true)}
    >
      <Title title={'Use your phone'} />
    </div>
  );
};
