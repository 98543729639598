import React, { useCallback, useEffect, useState } from 'react';
import { Phases } from '@magicyard/magicsnakes-game/src/Game';
// import Theme from '../assets/audio/theme.mp3';
// import PlayingMusic from '../assets/audio/playing.mp3';
// import QuestionMusic from '../assets/audio/question.mp3';
// import AnswerCorrectMusic from '../assets/audio/answerCorrect.mp3';

import { randomChoice } from '@magicyard/utils/numberUtils';
import { lastFocus, useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';

const searchParams = new URLSearchParams(window.location.search);
export const DISABLE_AUDIO = searchParams.get('version_name') === 'LG' || searchParams.get('disable_sound') === '1';

const newMusic = (src: string | undefined) => {
  if (DISABLE_AUDIO) {
    return null;
  }
  const newAudio = new Audio(src);
  newAudio.loop = true;
  newAudio.volume = 0.8;
  return newAudio;
};

const newOneshot = (src: string | undefined) => {
  if (DISABLE_AUDIO) {
    return null;
  }
  const newAudio = new Audio(src);
  newAudio.loop = false;
  newAudio.volume = 1;
  return newAudio;
};

export const AudioStagesMap: { [key in Phases]: HTMLAudioElement | null } = {
  [Phases.Sync]: null,
  [Phases.Playing]: null,
  [Phases.GameEnd]: null,
  [Phases.Tutorial]: null,
};

export const AudioManager = ({ phase, isTransition }: { phase: Phases; isTransition: boolean }) => {
  if (DISABLE_AUDIO) {
    return <></>;
  }
  return <AudioManagerInner phase={phase} isTransition={isTransition} />;
};

export const AudioManagerInner = ({ phase, isTransition }: { phase: Phases; isTransition: boolean }) => {
  const [activeSoundTrack, setActiveSoundTrack] = useState<Phases | null>(null);
  const [showAudioEnabler, setShowAudioEnabler] = useState(false);
  const [isInFocus, setIsInFocus] = useState(true);

  const onBlur = useCallback(() => {
    setIsInFocus(false);
    AudioStagesMap[phase]?.pause();
  }, [phase]);
  const onFocus = useCallback(() => {
    if (AudioStagesMap[phase]?.paused) {
      setIsInFocus(true);
      AudioStagesMap[phase]?.play();
    }
  }, [phase]);

  useNativeFocus(onFocus, onBlur);
  const playAudioIfFocused = useCallback(() => {
    setShowAudioEnabler(false);
    const curr = AudioStagesMap[phase];
    console.log(isInFocus, curr, 'playing?');
    if (isInFocus && curr !== null) {
      return curr.play();
    } else {
      return new Promise(() => undefined);
    }
  }, [isInFocus, phase]);

  const turnVolumeDown = useCallback((target: Phases) => {
    const curr = AudioStagesMap[target];
    if (curr === null) {
      return;
    }
    curr.pause();
    curr.currentTime = 0;
  }, []);

  useEffect(() => {
    const shouldPlayNewTrack = activeSoundTrack !== phase && isInFocus;
    const shouldStopOldTrack = activeSoundTrack !== null && shouldPlayNewTrack;
    if (shouldStopOldTrack) {
      try {
        turnVolumeDown(activeSoundTrack);
      } catch (err) {
        console.log(err);
      }
    }
    if (shouldPlayNewTrack) {
      setActiveSoundTrack(phase);
    }
  }, [activeSoundTrack, isInFocus, isTransition, phase, turnVolumeDown]);

  useEffect(() => {
    if (AudioStagesMap[phase]) {
      playAudioIfFocused()
        .then(() => {
          setShowAudioEnabler(false);
        })
        .catch(() => {
          setShowAudioEnabler(true);
        });
    } else console.log('Could Not find sound track');
  }, [activeSoundTrack, playAudioIfFocused, phase]);

  if (showAudioEnabler) {
    return (
      <button
        id="audio-manager--init"
        style={{ position: 'absolute', top: 10, left: 10, zIndex: 99999 }}
        onClick={playAudioIfFocused}
      >
        Enable Audio
      </button>
    );
  }

  return null;
};

export const stopMusic = () => {
  Object.values(AudioStagesMap).map((x) => x?.pause());
};

export const useOneshot = (oneshot: HTMLAudioElement | null, key?: string | number | null) => {
  useEffect(() => {
    if (key !== null) {
      playOneshot(oneshot);
    }
  }, [oneshot, key]);
};

export const useRandomOneshot = (oneshot: (HTMLAudioElement | null)[], key?: string | number | null) => {
  useEffect(() => {
    if (key !== null) {
      playOneshot(randomChoice(oneshot));
    }
  }, [oneshot, key]);
};

export const playOneshot = (oneshot: HTMLAudioElement | null) => {
  if (oneshot === null) {
    return;
  }

  if (lastFocus) {
    if (!oneshot.paused) {
      (oneshot.cloneNode() as HTMLAudioElement).play();
    } else {
      oneshot.play();
    }
  }
};
