import React, { useEffect, useRef, useState } from 'react';
import styles from './Tutorial.module.css';
import { track } from '@magicyard/shared/src/localAnalytics';
import { GameCanvases, InternalGameState, PlayerKeys, PlayerState } from './Playing';
import { useDebuggingKeys } from './PlayerKeys';
import { initPlayer } from './GameSimulation';

export const Tutorial = () => {
  useEffect(() => {
    track('Tutorial Presented');
  }, []);
  const canvasSize = { width: 1000, height: 1000 };

  const playerState = useRef(
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reduce<Record<string, PlayerState>>((acc, _, i) => {
      acc[i.toString()] = initPlayer(canvasSize, true);
      return acc;
    }, {})
  );
  const pressedKeys = useRef(
    Object.keys(playerState.current).reduce<PlayerKeys>((acc, key) => {
      acc[key] = { left: false, right: false };
      return acc;
    }, {})
  );

  const resetState = () => {
    Object.keys(playerState.current).forEach((key) => {
      playerState.current[key] = initPlayer(canvasSize, true);
    });
    gameState.current.winner = null;
  };

  const [gameKey, setGameKey] = useState(0);

  useDebuggingKeys(pressedKeys.current);
  const gameState = useRef<InternalGameState>({ winner: null, round: 1, scoreToWin: 10 });
  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Magic snakes</div>
          <div>Choose a game mode on your phone!</div>
        </div>
      </div>
      <div style={{ opacity: 0.6, marginTop: 40 }}>
        <GameCanvases
          onPlayerKilled={() => undefined}
          kickoff={{ type: 'immediate' }}
          gameState={gameState.current}
          onRoundEnd={() => {
            resetState();
            setGameKey((old) => old + 1);
          }}
          pressedKeys={pressedKeys.current}
          playerState={playerState.current}
          canvasSize={canvasSize}
          key={gameKey}
          onPowerUpCollected={() => undefined}
        />
      </div>
    </div>
  );
};
