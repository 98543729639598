import { Point } from '@magicyard/magicsnakes-game/src/Types';
import { CanvasSize, PlayerState } from './Playing';
import { Circle, PowerUp } from './GameSimulation';

export function AABB(
  square1: { p: Point; width: number; height: number },
  square2: { p: Point; width: number; height: number }
): boolean {
  return !(
    square1.p.x + square1.width < square2.p.x ||
    square1.p.x > square2.p.x + square2.width ||
    square1.p.y + square1.height < square2.p.y ||
    square1.p.y > square2.p.y + square2.height
  );
}

export function isCounterClockwiseOrientation(p1: Point, p2: Point, p3: Point) {
  return (p3.y - p1.y) * (p2.x - p1.x) > (p2.y - p1.y) * (p3.x - p1.x);
}

export function isIntersecting(p1: Point, p2: Point, p3: Point, p4: Point) {
  return (
    isCounterClockwiseOrientation(p1, p3, p4) != isCounterClockwiseOrientation(p2, p3, p4) &&
    isCounterClockwiseOrientation(p1, p2, p3) != isCounterClockwiseOrientation(p1, p2, p4)
  );
}

export function calculateCollisionPoint(rot: number, rotOffset: number, radius: number, nextPoint: Point): Point {
  const collisionIncVector = rotateVector({ x: radius * 4, y: 0 }, rot + rotOffset);

  return { x: nextPoint.x + collisionIncVector.x, y: nextPoint.y + collisionIncVector.y };
}

export function getRandomNumber(min: number, max: number): number {
  const randomFloat: number = Math.random();
  return randomFloat * (max - min) + min;
}

export function rotateVector(vector: { x: number; y: number }, angleInRadians: number): { x: number; y: number } {
  const x = vector.x;
  const y = vector.y;

  // Calculate the new x and y components after rotation
  const newX = x * Math.cos(angleInRadians) - y * Math.sin(angleInRadians);
  const newY = x * Math.sin(angleInRadians) + y * Math.cos(angleInRadians);

  return { x: newX, y: newY };
}

export function calculateDistance(vector1: Point, vector2: Point): number {
  const dx = vector2.x - vector1.x;
  const dy = vector2.y - vector1.y;

  return Math.sqrt(dx ** 2 + dy ** 2);
}

export const isCollidingWithPlayer = (
  head: Point,
  key: string,
  playerState: Record<string, PlayerState>,
  collisionPoints: Point[]
) =>
  Object.keys(playerState).some((otherKey) =>
    playerState[otherKey].segments.some((segment, segIndex) =>
      segment.some((p, i) =>
        otherKey === key && segIndex === playerState[key].segments.length - 1 && i > segment.length - 3
          ? false
          : i < playerState[otherKey].segments[segIndex].length - 1 &&
            collisionPoints.some((cp) => isIntersecting(head, cp, p, playerState[otherKey].segments[segIndex][i + 1]))
      )
    )
  );

export const getCollidedPowerUps = (head: Point, collisionPoints: Point[], powerUps: PowerUp[], key: string) => {
  const collidingWith: PowerUp[] = [];
  powerUps.forEach((powerUp) => {
    if (powerUp.collectedBy === null && isPointInCircle(head, powerUp.body)) {
      // collisionPoints.some(cp => circleLineIntersection(powerUp.body.center.x, powerUp.body.center.y, powerUp.body.radius, head.x, head.y, cp.x, cp.y) !== null)) {
      powerUp.collectedBy = key;
      collidingWith.push(powerUp);
    }
  });

  return collidingWith;
};

export const closestIntersectionWithPlayer = (
  head: Point,
  key: string,
  playerState: Record<string, PlayerState>,
  collisionPoints: Point[]
) => {
  const intersections: Point[] = [];
  for (const otherKey in playerState) {
    for (let segIndex = 0; segIndex < playerState[otherKey].segments.length; segIndex++) {
      const segment = playerState[otherKey].segments[segIndex];
      for (let i = 0; i < segment.length; i++) {
        const p = segment[i];
        if (
          !(otherKey === key && segIndex === playerState[key].segments.length - 1 && i > segment.length - 3) &&
          i < playerState[otherKey].segments[segIndex].length - 1
        ) {
          for (const cp of collisionPoints) {
            const intersection = checkIntersection(head, cp, p, playerState[otherKey].segments[segIndex][i + 1]);
            if (intersection !== null) {
              intersections.push(intersection);
            }
          }
        }
      }
    }
  }

  if (intersections.length === 0) {
    return null;
  }
  return findShortestDistance(head, intersections);
};

export const closestCircleIntersectionWithPlayer = (
  head: Point,
  key: string,
  playerState: Record<string, PlayerState>,
  radius: number
) => {
  const intersections: Point[] = [];
  for (const otherKey in playerState) {
    for (let segIndex = 0; segIndex < playerState[otherKey].segments.length; segIndex++) {
      const segment = playerState[otherKey].segments[segIndex];
      for (let i = 0; i < segment.length; i++) {
        const p = segment[i];
        if (
          !(otherKey === key && segIndex === playerState[key].segments.length - 1 && i > segment.length - 3) &&
          i < playerState[otherKey].segments[segIndex].length - 1
        ) {
          const intersection = circleLineIntersection(
            head.x,
            head.y,
            radius,
            p.x,
            p.y,
            playerState[otherKey].segments[segIndex][i + 1].x,
            playerState[otherKey].segments[segIndex][i + 1].y
          );
          if (intersection !== null) {
            intersections.push(intersection);
          }
        }
      }
    }
  }

  if (intersections.length === 0) {
    return null;
  }
  return findShortestDistance(head, intersections);
};

const findShortestDistance = (head: Point, intersections: Point[]) =>
  intersections.reduce((prev, curr) => {
    if (calculateDistance(head, prev) < calculateDistance(head, curr)) {
      return prev;
    } else {
      return curr;
    }
  }, intersections[0]);

export const intersectionPointWithWall = (canvasSize: CanvasSize, head: Point, collisionPoint: Point) => {
  const leftWall = checkIntersection(head, collisionPoint, { x: 0, y: 0 }, { x: 0, y: canvasSize.height });
  const rightWall = checkIntersection(
    head,
    collisionPoint,
    {
      x: canvasSize.width,
      y: canvasSize.height,
    },
    { x: canvasSize.width, y: 0 }
  );
  const floor = checkIntersection(
    head,
    collisionPoint,
    { x: 0, y: canvasSize.height },
    {
      x: canvasSize.width,
      y: canvasSize.height,
    }
  );
  const ceiling = checkIntersection(head, collisionPoint, { x: 0, y: 0 }, { x: canvasSize.width, y: 0 });
  const intersections: Point[] = [];
  if (leftWall !== null) {
    intersections.push(leftWall);
  }
  if (rightWall !== null) {
    intersections.push(rightWall);
  }
  if (floor !== null) {
    intersections.push(floor);
  }
  if (ceiling !== null) {
    intersections.push(ceiling);
  }

  if (intersections.length === 0) {
    return null;
  }

  return findShortestDistance(head, intersections);
};

export const isCollidingWithWall = (canvasSize: CanvasSize, p: Point) => {
  return p.x > canvasSize.width || p.x < 0 || p.y > canvasSize.height || p.y < 0;
};

export function checkIntersection(p1: Point, p2: Point, p3: Point, p4: Point) {
  const denom = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
  const numeA = (p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x);
  const numeB = (p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x);

  if (denom == 0) {
    if (numeA == 0 && numeB == 0) {
      return null;
    }
    return null;
  }

  const uA = numeA / denom;
  const uB = numeB / denom;

  if (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1) {
    return {
      x: p1.x + uA * (p2.x - p1.x),
      y: p1.y + uA * (p2.y - p1.y),
    };
  }

  return null;
}

export function circleLineIntersection(
  cx: number,
  cy: number,
  radius: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number
): Point | null {
  const A = x1 - cx;
  const B = y1 - cy;
  const C = x2 - x1;
  const D = y2 - y1;

  const E = C * C + D * D;
  const F = 2 * (A * C + B * D);
  const G = A * A + B * B - radius * radius;

  const discriminant = F * F - 4 * E * G;

  if (discriminant < 0) {
    return null; // No intersection
  }

  const t1 = (-F - Math.sqrt(discriminant)) / (2 * E);
  const t2 = (-F + Math.sqrt(discriminant)) / (2 * E);

  if (t1 >= 0 && t1 <= 1) {
    return { x: x1 + t1 * C, y: y1 + t1 * D };
  } else if (t2 >= 0 && t2 <= 1) {
    return { x: x1 + t2 * C, y: y1 + t2 * D };
  }

  return null; // No intersection within the line segment
}

function isPointInCircle(point: Point, circle: { radius: number; center: Point }): boolean {
  const distance = Math.sqrt(
    (point.x - circle.center.x) * (point.x - circle.center.x) +
      (point.y - circle.center.y) * (point.y - circle.center.y)
  );

  return distance <= circle.radius;
}
