import React, { createContext, useEffect, useRef } from 'react';
import { DbConnection } from 'driftdb';

const useDriftDbSocket = (drawingSocketUrl: string) => {
  const conn = useRef(new DbConnection());
  useEffect(() => {
    const curr = conn.current;
    curr
      .connect(drawingSocketUrl)
      .then(() => undefined)
      .catch(() => undefined);

    return () => {
      curr.disconnect();
    };
  }, [drawingSocketUrl]);

  return conn.current;
};
export const DriftDbSocketContext = createContext<DbConnection>(null as any);

export const useDriftDbSocketContext = () => {
  if (DriftDbSocketContext === null) {
    throw new Error('DriftDbSocketContext is null');
  }
  return React.useContext(DriftDbSocketContext);
};

export const DriftDbSocketProvider = ({ socketUrl, children }: { socketUrl: string; children: React.ReactChild }) => {
  const conn = useDriftDbSocket(socketUrl);

  return <DriftDbSocketContext.Provider value={conn}>{children}</DriftDbSocketContext.Provider>;
};
