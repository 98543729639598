import { getControllerUrlConnectDisplay } from '@magicyard/utils';
import React, { useContext } from 'react';
import { Display } from '@magicyard/shared/platform/lib/api';
import { URL_SHORTENING_API } from '@magicyard/shared/platform/lib/urlShortener';
import styles from './QrSign.module.css';

export const QrSign = (props: { display: Display; className?: string; size?: number | string }) => {
  return (
    <div className={`${styles['qr-sign_root']} ${props.className ?? ''}`}>
      <div
        className={styles['qr-sign_background']}
        style={props.size === undefined ? undefined : { width: props.size, height: props.size }}
      >
        <div className={styles['qr-sign_body']}>
          <div
            style={{
              backgroundImage: `url(${URL_SHORTENING_API}/qr?text=${encodeURIComponent(
                getControllerUrlConnectDisplay(props.display.id)
              )})`,
            }}
            className={styles['qr-sign_qr']}
          />
        </div>
        <div className={styles['qr-sign_text']}>code: {props.display.code}</div>
      </div>
    </div>
  );
};
