import { ActivePlayersArg, Ctx, PlayerID } from 'boardgame.io';
import { G } from './Game';
import { RandomAPI } from 'boardgame.io/dist/types/src/plugins/random/random';
import { EventsAPI } from 'boardgame.io/dist/types/src/plugins/events/events';
import { movesUtil } from './utils/moves.util';
import { assertIsDefined } from './utils/typeUtils';

export interface EventsAPIGuaranteed extends EventsAPI {
  endGame(gameover?: any): void;

  endPhase(): void;

  endStage(): void;

  endTurn(arg?: { next: PlayerID }): void;

  pass(arg?: { remove: true }): void;

  setActivePlayers(arg: ActivePlayersArg): void;

  setPhase(newPhase: string): void;

  setStage(newStage: string): void;
}

export interface CtxWithApi extends Ctx {
  events: EventsAPIGuaranteed;
  random: RandomAPI;
  playerID: PlayerID; // Assuming playerId is always present maybe wrong
}

export type BoardPropTypes = {
  ctx: CtxWithApi;
  events: unknown;
  moves: { [key in keyof typeof movesUtil]: (...arg: any) => void };
  playerID: PlayerID;
} & G;

export interface IPlayer {
  id: PlayerID;
  name: string;
  avatarUrl: string;
  controller_id: string;
  isBot: boolean;
}

export type Answer = { answer: string; timeTaken: number; promptIndex: number };

export interface DefaultGameState {
  didGameEnd: boolean;
  isTransition: boolean;
  phaseStartTime: number;
  players: IPlayer[];
  shouldForceSubmit: boolean;
}

export type PlayerState = {
  isDead: boolean;
  score: number;
};

export interface MagicSnakesGameState {
  stateForPlayer: Record<PlayerID, PlayerState>;
  playingSocketUrl: string;
}

export type Point = { x: number; y: number };

export type GameState = DefaultGameState & MagicSnakesGameState;

export const valueToColor: Record<string, string> = {
  '-1': '#eaeaea',
  '0': '#fbc117',
  '1': '#005a5a',
  '2': '#f85f00',
  '3': '#e40001',
  '4': '#732400',
  '5': '#004056',
  '6': '#9e0059',
  '7': '#548b2f',
  '8': '#00274e',
  '9': '#d54f30',
  '10': '#7f7f7f',
  '11': '#003366',
  '12': '#f6793f',
  '13': '#00539f',
  '14': '#fbbc00',
  '15': '#007acc',
};
function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function hexToRgba(hex: string, a: number) {
  const rgb = hexToRgb(hex);
  assertIsDefined(rgb);
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${a})`;
}
