import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { CONFIG, getMultiplayerMode } from '@magicyard/utils';
import { Client } from 'boardgame.io/react';
import { MagicSnakes, GameObject } from '@magicyard/magicsnakes-game/src/Game';
import Board from './components/Board';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { valueToColor } from '@magicyard/magicsnakes-game/src/Types';

console.log('Starting with config:\n', { CONFIG });

const getDemoData = (): { matchId: string; displayId: string | null } | null => {
  const params = new URLSearchParams(window.location.search);
  const matchId = params.get('matchID');
  const displayId = params.get('displayId');
  if (matchId === null) {
    return null;
  }

  return { matchId: matchId, displayId: displayId };
};
const demoData = getDemoData();

if (demoData !== null) {
  const BgioClient = Client({
    loading: () => (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundSize: 'contain',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: valueToColor[-1],
        }}
      >
        Loading...
      </div>
    ),
    game: MagicSnakes,
    board: (G: GameObject) => <Board {...{ ...G, display: undefined, onBack: () => undefined }} key={1} />,
    debug: true,
    multiplayer: getMultiplayerMode(),
  });

  const DemoWrapper = () => {
    useFullscreen();
    useForceScale('#root-magicsnakes-display');
    console.log('Rerendering with demo data111', demoData);

    return <BgioClient matchID={demoData.matchId} playerID={'0'} />;
  };

  ReactDOM.render(
    <React.StrictMode>
      <DemoWrapper />
    </React.StrictMode>,
    document.getElementById('root-magicsnakes-display')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      {/*@ts-ignore TODO */}
      <App />
    </React.StrictMode>,
    document.getElementById('root-magicsnakes-display')
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
