import { useDriftDbSocketContext } from '@magicyard/shared/src/DriftDbSocketContext';
import { useEffect } from 'react';
import { SequenceValue } from 'driftdb';
import { PlayerKeys } from './Playing';

export const useSocketKeys = (pressedKeys: PlayerKeys) => {
  const socket = useDriftDbSocketContext();
  useEffect(() => {
    const onBroadcast = (data: SequenceValue) => {
      const msg = data.value as { data: { left?: boolean; right?: boolean; playerId: string } };
      if (msg.data.left !== undefined) {
        pressedKeys[msg.data.playerId].left = msg.data.left;
      } else if (msg.data.right !== undefined) {
        pressedKeys[msg.data.playerId].right = msg.data.right;
      }
    };
    socket.subscribe('broadcast', onBroadcast);
    return () => {
      socket.unsubscribe('broadcast', onBroadcast);
    };
  }, [socket]);
};

export const useDebuggingKeys = (pressedKeys: PlayerKeys) => {
  useEffect(() => {
    return addDebuggingKeysListener(pressedKeys);
  }, []);
};

const addDebuggingKeysListener = (pressedKeys: PlayerKeys) => {
  const downListener = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      pressedKeys[0].left = true;
    } else if (e.key === 'ArrowRight') {
      pressedKeys[0].right = true;
    }
  };

  const upListener = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      pressedKeys[0].left = false;
    } else if (e.key === 'ArrowRight') {
      pressedKeys[0].right = false;
    }
  };

  window.addEventListener('keydown', downListener);
  window.addEventListener('keyup', upListener);

  return () => {
    window.removeEventListener('keydown', downListener);
    window.removeEventListener('keyup', upListener);
  };
};
