import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGameObject } from '../../store/GameContext';
import { Point, valueToColor } from '@magicyard/magicsnakes-game/src/Types';
import { assertIsDefined } from '@magicyard/magicsnakes-game/src/utils/typeUtils';
import { initPlayer, kickoffGame, PowerUp, preKickoff } from './GameSimulation';
import { useDebuggingKeys, useSocketKeys } from './PlayerKeys';
import styles from './Playing.module.css';

export type PlayerState = {
  segments: Point[][];
  rot: number;
  // When did this player lose
  collisionOrder: number | null;
  gapState: {
    timeLeft: number;
    isMakingGap: boolean;
  };
  addCollisionPointCounter: number;
  isBot: boolean;
  radius: number;
};

export type CanvasSize = {
  width: number;
  height: number;
};

export type PlayerKeys = Record<
  string,
  {
    left: boolean;
    right: boolean;
  }
>;

export type InternalGameState = {
  winner: string | null;
  round: number;
  scoreToWin: number;
};

export const Playing = () => {
  const { G, moves } = useGameObject();
  const [kickoffState, setKickoffState] = useState<null | (() => void)>(null);
  const [didRoundFinish, setDidRoundFinish] = useState(false);
  const [didHudFinish, setDidHudFinish] = useState(false);
  const canvasSize = { width: 1500, height: 1060 };
  const gameState = useRef<InternalGameState>({ winner: null, round: 0, scoreToWin: 20 });
  const playerState = useRef(
    G.players.reduce<Record<string, PlayerState>>((acc, p, i) => {
      acc[p.id] = initPlayer(canvasSize, G.players[i].isBot);
      return acc;
    }, {})
  );
  const pressedKeys = useRef(
    Object.keys(playerState.current).reduce<PlayerKeys>((acc, key) => {
      acc[key] = { left: false, right: false };
      return acc;
    }, {})
  );

  useSocketKeys(pressedKeys.current);
  useDebuggingKeys(pressedKeys.current);

  return (
    <div style={{ position: 'absolute', left: 10, width: canvasSize.width }}>
      <GameCanvases
        onPlayerKilled={(playerId, order) => {
          moves.killPlayer(playerId, order);
        }}
        kickoff={{
          type: 'delayed',
          onPreKickoffFinished: (kickoff) => setKickoffState(() => kickoff),
        }}
        canvasSize={canvasSize}
        onRoundEnd={(winner) => {
          setDidRoundFinish(true);
          moves.endRound(winner);
        }}
        playerState={playerState.current}
        gameState={gameState.current}
        pressedKeys={pressedKeys.current}
        onPowerUpCollected={(p) => {
          moves.collectPowerUp(p.collectedBy);
        }}
        key={gameState.current.round}
      >
        {kickoffState === null ? undefined : (
          <>
            <Hud playerState={playerState.current} onFinish={() => setDidHudFinish(true)} canvasSize={canvasSize} />
            {didHudFinish && (
              <RoundAnnouncement
                round={gameState.current.round + 1}
                scoreToWin={gameState.current.scoreToWin}
                onFinish={() => {
                  kickoffState();
                  setDidHudFinish(false);
                }}
              />
            )}
          </>
        )}
      </GameCanvases>
      {didRoundFinish && (
        <X
          canvasSize={canvasSize}
          playerState={playerState.current}
          winner={gameState.current.winner!}
          onFinish={() => {
            setDidRoundFinish(false);
            gameState.current = {
              winner: null,
              round: gameState.current.round + 1,
              scoreToWin: gameState.current.scoreToWin,
            };
            playerState.current = G.players.reduce<Record<string, PlayerState>>((acc, p, i) => {
              acc[p.id] = initPlayer(canvasSize, G.players[i].isBot);
              return acc;
            }, {});
          }}
        />
      )}
    </div>
  );
};

const X = ({
  playerState,
  winner,
  canvasSize,
  onFinish,
}: {
  playerState: Record<string, PlayerState>;
  winner: string;
  canvasSize: CanvasSize;
  onFinish: () => void;
}) => {
  const { G } = useGameObject();
  const head = getHead(playerState[winner]);
  return (
    <div
      className={styles.winnerAnnouncement}
      onAnimationEnd={onFinish}
      style={{
        position: 'absolute',
        top: canvasSize.height / 2 - 50,
        left: canvasSize.width / 2 - 200,
        color: valueToColor[winner],
      }}
    >
      {G.players[+winner].name + ' won the round!'}
    </div>
  );
};

export const GameCanvases = ({
  canvasSize,
  onRoundEnd,
  playerState,
  pressedKeys,
  gameState,
  kickoff,
  children,
  onPlayerKilled,
  onPowerUpCollected,
}: {
  kickoff:
    | {
        type: 'immediate';
      }
    | {
        type: 'delayed';
        onPreKickoffFinished: (kickoff: () => void) => void;
      };
  onPlayerKilled: (playerId: string, order: number) => void;
  canvasSize: CanvasSize;
  onRoundEnd: (winner: string) => void;
  playerState: Record<string, PlayerState>;
  pressedKeys: PlayerKeys;
  gameState: InternalGameState;
  children?: React.ReactChild;
  onPowerUpCollected: (p: PowerUp) => void;
}) => {
  const mainCanvas = useRef<HTMLCanvasElement>(null);
  const headCanvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const mainCanvasCtx = mainCanvas.current?.getContext('2d');
    const headCanvasCtx = headCanvas.current?.getContext('2d');
    assertIsDefined(mainCanvasCtx);
    assertIsDefined(headCanvasCtx);

    const makeKickoff = () => {
      kickoffGame({
        canvasSize: canvasSize,
        onGameEnd: onRoundEnd,
        playerState: playerState,
        pressedKeys: pressedKeys,
        gameState: gameState,
        headCanvasCtx: headCanvasCtx,
        mainCanvasCtx: mainCanvasCtx,
        onPlayerKilled: onPlayerKilled,
        onPowerUpCollected: onPowerUpCollected,
      });
    };

    switch (kickoff.type) {
      case 'immediate':
        makeKickoff();
        break;
      case 'delayed':
        preKickoff({
          canvasSize: canvasSize,
          playerState: playerState,
          pressedKeys: pressedKeys,
          gameState: gameState,
          headCanvasCtx: headCanvasCtx,
          mainCanvasCtx: mainCanvasCtx,
        });
        kickoff.onPreKickoffFinished(makeKickoff);
        break;
    }
  }, []);

  return (
    <div style={{ position: 'relative', border: '1px solid black' }}>
      <canvas ref={mainCanvas} width={canvasSize.width} height={canvasSize.height} />
      <canvas
        ref={headCanvas}
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}
        width={canvasSize.width}
        height={canvasSize.height}
      />
      {children}
    </div>
  );
};

const getHead = (playerState: PlayerState) => {
  return playerState.segments[playerState.segments.length - 1][
    playerState.segments[playerState.segments.length - 1].length - 1
  ];
};

const Hud = ({
  playerState,
  onFinish,
  canvasSize,
}: {
  playerState: Record<string, PlayerState>;
  onFinish: () => void;
  canvasSize: CanvasSize;
}) => {
  const { G } = useGameObject();
  const animationCounter = useRef(0);
  const [step, setStep] = useState(0);

  const sortedPlayers = useMemo(
    () => Object.keys(playerState).sort((p1, p2) => getHead(playerState[p1]).x - getHead(playerState[p2]).x),
    []
  );

  return (
    <div
      className={styles.hudRoot}
      onAnimationEnd={() => {
        if (step === 0) {
          animationCounter.current++;
          if (animationCounter.current === Object.keys(playerState).length) {
            setStep(1);
          }
        }
      }}
      onTransitionEnd={() => {
        if (step === 1) {
          animationCounter.current++;
          if (animationCounter.current === Object.keys(playerState).length * 2 + 1) {
            onFinish();
          }
        }
      }}
    >
      {sortedPlayers.map((p, i) => {
        const head = getHead(playerState[p]);
        return (
          <div
            className={styles.hudPlayerContainer}
            key={p}
            style={{
              color: valueToColor[p],
              top: step >= 1 ? i * 60 + 20 : step >= 3 ? undefined : head.y,
              left: step >= 1 ? canvasSize.width + 20 : step >= 3 ? undefined : head.x,
              animationDelay: `${0.9 * i}s`,
              transitionDelay: `${0.3 * i}s`,
              width: 1920 - (canvasSize.width + 60),
              display: 'flex',
            }}
          >
            <div className={styles.hudPlayerNamePart}>{G.players[+p].name}</div>
            <div className={styles.hudPlayerScorePart}>{': ' + G.stateForPlayer[p].score}</div>
          </div>
        );
      })}
    </div>
  );
};

const RoundAnnouncement = ({
  onFinish,
  round,
  scoreToWin,
}: {
  onFinish: () => void;
  round: number;
  scoreToWin: number;
}) => {
  const [step, setStep] = useState(0);
  return (
    <div className={styles.roundAnnouncementContainer}>
      <div>
        Round {round} of {scoreToWin}
      </div>
      <ThreeTwoOne onFinish={() => setStep(3)} />
      {step >= 1 && (
        <div className={styles.go} onAnimationEnd={onFinish}>
          GO!
        </div>
      )}
    </div>
  );
};

const ThreeTwoOne = ({ onFinish }: { onFinish: () => void }) => {
  const [count, setCount] = useState(3);
  useEffect(() => {
    if (count === 0) {
      onFinish();
    }
  }, [count]);

  if (count === 0) {
    return <></>;
  }

  return (
    <div className={styles.threeTwoOne} onAnimationEnd={() => setCount(count - 1)} key={count}>
      {count}
    </div>
  );
};
