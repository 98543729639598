import { Point, valueToColor } from '@magicyard/magicsnakes-game/src/Types';
import {
  calculateCollisionPoint,
  getCollidedPowerUps,
  getRandomNumber,
  isCollidingWithPlayer,
  isCollidingWithWall,
  rotateVector,
} from './Math';
import { stepBot, initBots } from './Bot';
import { CanvasSize, InternalGameState, PlayerKeys, PlayerState } from './Playing';
import simplify from 'simplify-js';
import plusOne from '../../assets/plus1.png';

const plusOneImage = new Image();
plusOneImage.src = plusOne;

const rotationScalar = 1.4;
const movementSpeed = 1.6;
export const defaultRadius = 2.5;

export interface Circle {
  radius: number;
  center: Point;
}

export interface PowerUp {
  type: 'score';
  body: Circle;
  collectedBy: string | null;
}

export interface PowerUpState {
  powerUps: PowerUp[];
  lastPowerUpSpawn: number;
  maxPowerUps: number;
}

const getNextGapState = (nextGapState: boolean) => {
  return {
    timeLeft: nextGapState ? getRandomNumber(16, 28) : getRandomNumber(50, 380),
    isMakingGap: nextGapState,
  };
};

export const initPlayer = (canvasSize: CanvasSize, isBot: boolean): PlayerState => {
  const head = {
    x: getRandomNumber(150, canvasSize.width - 150),
    y: getRandomNumber(150, canvasSize.height - 150),
  };
  return {
    // Must include the initial location twice to make the first line
    segments: [[head, head]],
    rot: getRandomNumber(0, 2 * Math.PI),
    collisionOrder: null,
    gapState: getNextGapState(false),
    addCollisionPointCounter: 0,
    isBot: isBot,
    radius: defaultRadius,
  };
};

// Step once so that players can see where they'll be spawned
export const preKickoff = ({
  canvasSize,
  playerState,
  pressedKeys,
  gameState,
  headCanvasCtx,
  mainCanvasCtx,
}: {
  canvasSize: CanvasSize;
  playerState: Record<string, PlayerState>;
  pressedKeys: PlayerKeys;
  gameState: InternalGameState;
  headCanvasCtx: CanvasRenderingContext2D;
  mainCanvasCtx: CanvasRenderingContext2D;
}) => {
  headCanvasCtx.fillStyle = 'red';
  mainCanvasCtx.lineCap = 'round';
  step({
    timeModifier: { n: 15 },
    canvasSize,
    playerState,
    pressedKeys,
    collisionPoints: [],
    gameState,
    headCanvasCtx,
    mainCanvasCtx,
    onPlayerKilled: () => undefined,
    powerUpState: { powerUps: [], lastPowerUpSpawn: 0, maxPowerUps: 0 },
    onPowerUpCollected: () => undefined,
  });
};

export const kickoffGame = ({
  onGameEnd,
  canvasSize,
  playerState,
  pressedKeys,
  gameState,
  headCanvasCtx,
  mainCanvasCtx,
  onPlayerKilled,
  onPowerUpCollected,
}: {
  onGameEnd: (winner: string) => void;
  canvasSize: CanvasSize;
  playerState: Record<string, PlayerState>;
  pressedKeys: PlayerKeys;
  gameState: InternalGameState;
  headCanvasCtx: CanvasRenderingContext2D;
  mainCanvasCtx: CanvasRenderingContext2D;
  onPlayerKilled: (playerId: string, collisionOrder: number) => void;
  onPowerUpCollected: (p: PowerUp) => void;
}) => {
  let timeModifier = { n: 1 };
  initBots(playerState);

  headCanvasCtx.fillStyle = 'red';
  mainCanvasCtx.lineCap = 'round';
  let frameLockAcc = 0;
  let d0 = 0;

  const powerUpState: PowerUpState = {
    powerUps: [],
    lastPowerUpSpawn: 0,
    maxPowerUps: 3,
  };

  // Save memory by reusing the array
  const collisionPoints: Point[] = [];

  const loop = (d: number) => {
    const delta = d - d0;
    d0 = d;
    frameLockAcc += delta;
    // Try to achieve 60 frames per second
    const stepLock = 1000 / 60;
    while (frameLockAcc > stepLock) {
      step({
        timeModifier,
        canvasSize,
        playerState,
        pressedKeys,
        collisionPoints,
        gameState,
        headCanvasCtx,
        mainCanvasCtx,
        onPlayerKilled,
        powerUpState,
        onPowerUpCollected,
      });
      frameLockAcc -= stepLock;
    }
    if (gameState.winner !== null) {
      onGameEnd(gameState.winner);
      return;
    }
    requestAnimationFrame(loop);
  };

  // Start d0 at the correct initial delta
  requestAnimationFrame((initial) => {
    d0 = initial;
    requestAnimationFrame(loop);
  });
};

const step = ({
  timeModifier,
  canvasSize,
  playerState,
  pressedKeys,
  collisionPoints,
  gameState,
  headCanvasCtx,
  mainCanvasCtx,
  onPlayerKilled,
  onPowerUpCollected,
  powerUpState,
}: {
  timeModifier: {
    n: number;
  };
  canvasSize: CanvasSize;
  playerState: Record<string, PlayerState>;
  pressedKeys: PlayerKeys;
  collisionPoints: Point[];
  gameState: InternalGameState;
  headCanvasCtx: CanvasRenderingContext2D;
  mainCanvasCtx: CanvasRenderingContext2D;
  onPlayerKilled: (playerId: string, collisionOrder: number) => void;
  powerUpState: PowerUpState;
  onPowerUpCollected: (p: PowerUp) => void;
}) => {
  // TEMP for debugging
  // headCanvasCtx.clearRect(0, 0, canvasSize.width, canvasSize.height);

  powerUpState.lastPowerUpSpawn += powerUpState.maxPowerUps > powerUpState.powerUps.length ? timeModifier.n : 0;
  if (powerUpState.lastPowerUpSpawn > getRandomNumber(200, 400)) {
    const radius = getRandomNumber(20, 25);
    const newPowerup: PowerUp = {
      type: 'score',
      collectedBy: null,
      body: {
        radius: radius,
        center: {
          x: getRandomNumber(radius * 2, canvasSize.width - radius * 2),
          y: getRandomNumber(radius * 2, canvasSize.height - radius * 2),
        },
      },
    };

    powerUpState.powerUps.push(newPowerup);

    headCanvasCtx.beginPath();
    headCanvasCtx.drawImage(
      plusOneImage,
      newPowerup.body.center.x - radius,
      newPowerup.body.center.y - radius,
      radius * 2,
      radius * 2
    );
    headCanvasCtx.closePath();

    powerUpState.lastPowerUpSpawn = 0;
  }

  powerUpState.powerUps = powerUpState.powerUps.filter((p) => {
    if (p.collectedBy !== null) {
      headCanvasCtx.clearRect(
        p.body.center.x - p.body.radius * 2 - 1,
        p.body.center.y - p.body.radius * 2 - 1,
        p.body.radius * 4 + 2,
        p.body.radius * 4 + 2
      );
      return false;
    }
    return true;
  });

  Object.keys(playerState).forEach((key) => {
    const curr = playerState[key];

    if (curr.collisionOrder !== null) {
      return;
    }

    if (curr.gapState.timeLeft <= 0) {
      curr.gapState = getNextGapState(!curr.gapState.isMakingGap);
      if (curr.gapState.isMakingGap) {
        curr.segments[curr.segments.length - 1] = simplify(curr.segments[curr.segments.length - 1], 1, true);
        curr.segments.push([
          curr.segments[curr.segments.length - 1][curr.segments[curr.segments.length - 1].length - 1],
        ]);
      } else {
        curr.segments[curr.segments.length - 1].push(
          curr.segments[curr.segments.length - 1][curr.segments[curr.segments.length - 1].length - 1]
        );
      }
    } else {
      curr.gapState.timeLeft -= timeModifier.n;
    }

    if (pressedKeys[key].left) {
      curr.rot -= 0.008 * Math.PI * rotationScalar * timeModifier.n;
    } else if (pressedKeys[key].right) {
      curr.rot += 0.008 * Math.PI * rotationScalar * timeModifier.n;
    }
    const currLine = curr.segments[curr.segments.length - 1];
    const head = currLine[currLine.length - 1];
    const incVector = rotateVector({ x: movementSpeed * timeModifier.n, y: 0 }, curr.rot);
    const nextPoint = { x: head.x + incVector.x, y: head.y + incVector.y };

    collisionPoints[0] = calculateCollisionPoint(curr.rot, 0, curr.radius, nextPoint);
    collisionPoints[1] = calculateCollisionPoint(curr.rot, Math.PI / 2, curr.radius, nextPoint);
    collisionPoints[2] = calculateCollisionPoint(curr.rot, -Math.PI / 2, curr.radius, nextPoint);
    collisionPoints[3] = calculateCollisionPoint(curr.rot, Math.PI / 4, curr.radius, nextPoint);
    collisionPoints[4] = calculateCollisionPoint(curr.rot, -Math.PI / 4, curr.radius, nextPoint);

    const ps = getCollidedPowerUps(head, collisionPoints, powerUpState.powerUps, key);

    for (let i = 0; i < ps.length; i++) {
      onPowerUpCollected(ps[i]);
    }

    // Check if collided with any other player
    curr.collisionOrder = curr.gapState.isMakingGap
      ? null
      : isCollidingWithPlayer(head, key, playerState, collisionPoints) || isCollidingWithWall(canvasSize, nextPoint)
      ? Object.keys(playerState).filter((key) => playerState[key].collisionOrder !== null).length
      : null;
    if (curr.collisionOrder !== null) {
      if (
        !curr.isBot &&
        Object.keys(playerState)
          .filter((key) => playerState[key].collisionOrder === null)
          .every((key) => playerState[key].isBot)
      ) {
        // Speed up the game so that players don't have to wait..
        timeModifier.n = 3;
      }
      onPlayerKilled(key, curr.collisionOrder);
      if (curr.collisionOrder === Object.keys(playerState).length - 2) {
        gameState.winner = Object.keys(playerState).find((key) => playerState[key].collisionOrder === null) ?? 'tie';
      }
      return;
    }

    if (curr.isBot) {
      pressedKeys[key] = stepBot(defaultRadius, canvasSize, playerState, key, curr, headCanvasCtx, head);
    }

    headCanvasCtx.translate(head.x, head.y);
    headCanvasCtx.rotate(curr.rot);
    headCanvasCtx.clearRect(-curr.radius * 2 - 1, -curr.radius * 2 - 1, curr.radius * 4 + 2, curr.radius * 4 + 2);
    headCanvasCtx.rotate(-curr.rot);
    headCanvasCtx.translate(-head.x, -head.y);
    headCanvasCtx.beginPath();
    headCanvasCtx.arc(nextPoint.x, nextPoint.y, curr.radius * 2, 0, 2 * Math.PI);
    headCanvasCtx.fill();
    headCanvasCtx.closePath();

    // temp for debugging
    // for (const cp of collisionPoints) {
    //   headCanvasCtx.beginPath();
    //   headCanvasCtx.arc(cp.x, cp.y, curr.radius * 2, 0, 2 * Math.PI);
    //   headCanvasCtx.fill();
    //   headCanvasCtx.closePath();
    // }
    // for (const seg of curr.segments) {
    //   for (const p of seg) {
    //     headCanvasCtx.beginPath();
    //     headCanvasCtx.arc(p.x, p.y, curr.radius * 2, 0, 2 * Math.PI);
    //     headCanvasCtx.fill();
    //     headCanvasCtx.closePath();
    //   }
    // }

    if (curr.gapState.isMakingGap) {
      currLine[currLine.length - 1] = nextPoint;
    } else {
      if (pressedKeys[key].left || pressedKeys[key].right) {
        if (curr.addCollisionPointCounter === 0) {
          currLine.push(nextPoint);
        } else if (curr.addCollisionPointCounter % 10 === 0) {
          currLine.push(nextPoint);
        } else {
          currLine[currLine.length - 1] = nextPoint;
        }
        curr.addCollisionPointCounter++;
      } else {
        if (curr.addCollisionPointCounter !== 0) {
          currLine.push(nextPoint);
          curr.addCollisionPointCounter = 0;
        } else {
          currLine[currLine.length - 1] = nextPoint;
        }
      }

      mainCanvasCtx.strokeStyle = valueToColor[key];
      mainCanvasCtx.beginPath();
      mainCanvasCtx.moveTo(head.x, head.y);
      mainCanvasCtx.lineWidth = curr.radius * 4;
      mainCanvasCtx.lineTo(nextPoint.x, nextPoint.y);
      mainCanvasCtx.stroke();
      mainCanvasCtx.moveTo(-head.x, -head.y);
      mainCanvasCtx.closePath();
    }
  });
};
